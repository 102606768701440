import React, {useEffect, useState} from "react";
import {db} from "../firebase/clientApp";

const how=[
    {label:'Amici', value:'amici'},
    {label:'Instagram', value:'instagram'},
    {label:'Facebook', value:'facebook'},
    {label:'Dice', value:'dice'},
    {label:'Manifesti', value:'manifesti'},
    {label:'Vi conosco già', value:'viConoscoGia'},
    {label:'Google', value:'google'},
    {label:'Altro', value:'altro'},
]

const why=[
    {label:'Negativo', value:'negativo'},
    {label:'Medio', value:'medio'},
    {label:'Positivo', value:'positivo'}
]

export const Report = () => {
    const [come, setCome] = useState({})
    const [tornerai, setTornerai] = useState({})
    const [totaleCome, setTotaleCome] = useState(0)
    const [totaleTornerai, setTotaleTornerai] = useState(0)

    useEffect(() => {
        getData()
    },[])

    async function getData (){
        db.ref('/').on('value', (s) => {
            let res = s.val();
            setCome(res.come)
            setTotaleCome(Object.values(res.come).reduce((a,b) => a+b))
            setTornerai(res.feedback)
            setTotaleTornerai(Object.values(res.feedback).reduce((a,b) => a+b))
        })
    }

    return (
        <div style={{width:'100%', maxWidth:'60rem', margin:'auto', color:'#fff'}}>
            <div style={{padding:'1rem', paddingBottom:'4rem'}}>
                <p className={'domanda'}>report</p>
                <div>
                    <p style={{fontSize:'1.6rem', fontWeight:'bold'}}>Come ci hai conosciuto? <span>{totaleCome}</span></p>
                    <div className={'report-block'}>
                        {how.map((k,i) =>
                            <div style={{fontSize:'1.3rem',fontWeight:'bold'}} key={i+30}>
                                <p style={{margin:'0.4rem 0'}}>
                                    <span >{k.label}</span>
                                    <span>: </span>
                                    <span style={{color:'#f6a45f'}}>{come[k.value] ?? 0}</span>
                                </p>
                            </div>)
                        }
                    </div>
                </div>
                <div>
                    <p style={{fontSize:'1.6rem', fontWeight:'bold'}}>Ti è piaciuto il festival? <span>{totaleTornerai}</span></p>
                    <div className={'report-block'}>
                        {why.map((k,i) =>
                            <div style={{fontSize:'1.3rem',fontWeight:'bold'}} key={i+30}>
                                <p style={{margin:'0.4rem 0'}}>
                                    <span >{k.label}</span>
                                    <span>: </span>
                                    <span style={{color:'#f6a45f'}}>{tornerai[k.value]}</span>
                                </p>
                            </div>)
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}
