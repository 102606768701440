import React from "react";

export const Home = () => {
    return (
        <div style={{width: '100%', textAlign: 'center'}}>
            <div style={{display: 'grid', gridTemplateColumns: 'auto auto', justifyContent: 'center'}}>
                <div style={{padding: '0.6rem'}}>
                    <a href='/come'>
                        <button>
                            COME CI HAI CONOSCIUTO?
                        </button>
                    </a>
                </div>
                <div style={{padding: '0.6rem'}}>
                    <a href='/tornerai'>
                        <button>
                            Feedback
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}
