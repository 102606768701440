import React from 'react';
import './App.scss';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Come} from "./pages/Come";
import {Tornerai} from "./pages/Tornerai";
import logo from './assets/logo.png'
import {Report} from "./pages/Report";

function App() {
    return (
        <div>
            <div style={{width:'100%', textAlign:'center'}}>
                <img src={logo} style={{width:'100%', maxWidth:'9rem'}} alt={'logo'}/>
            </div>
            <Routes>
                <Route path='/' element={<Home/>}></Route>
                <Route path='/come' element={<Come/>}></Route>
                <Route path='/tornerai' element={<Tornerai/>}></Route>
                <Route path='/report' element={<Report/>}/>
            </Routes>
            <div className={'background-icons'}>
                {
                    [1,2,3].map((item, index) =>
                        <div key={index} className={`background-icon icon-${item}`}>
                            <img src={require('./assets/icon-'+item+'.png')} style={{width:'100%'}} alt={`icon-${item}`}/>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default App;
