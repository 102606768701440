import React, {useState} from "react";
import {db} from "../firebase/clientApp";
import {Check} from "../components/Check";

const how=[
    {label:'amici', value:'amici'},
    {label:'instagram', value:'instagram'},
    {label:'facebook', value:'facebook'},
    {label:'dice', value:'dice'},
    {label:'manifesti', value:'manifesti'},
    {label:'vi conosco già', value:'viConoscoGia'},
    {label:'google', value:'google'},
    {label:'altro', value:'altro'},
]

export const Come = () => {
    const [success, setSuccess] = useState(false)

    const vote = async (value) => {
        db.ref('/come').child(value).transaction(function (searches) {
            return (searches || 0) + 1
        })
            .then(()=>{
                setSuccess(true)
                setTimeout(() => setSuccess(false),3000)
            })
            .catch(()=>console.log('problema'))
    }

    return(
        <div style={{textAlign:'center'}}>
            {success && <Check/>}
            <p className='domanda' style={{margin:'0 0 1rem 0'}}>COME CI HAI CONOSCIUTO?</p>
            <div style={{display:'grid', gridTemplateColumns: 'auto auto', justifyContent:'center'}}>
                {how.map((h, i) =>
                    <div style={{padding:'0.6rem'}} key={i}>
                        <button onClick={() => vote(h.value)}>{h.label}</button>
                    </div>
                )}
            </div>
        </div>
    )
}
