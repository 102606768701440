import React, {useState} from "react";
import {db} from "../firebase/clientApp";
import {Check} from "../components/Check";

const why=[
    {label:'negativo', value:'negativo', icon:'https://assets.ccbp.in/frontend/react-js/sad-emoji-img.png'},
    {label:'medio', value:'medio', icon: 'https://assets.ccbp.in/frontend/react-js/none-emoji-img.png'},
    {label:'positivo', value:'positivo', icon: 'https://assets.ccbp.in/frontend/react-js/happy-emoji-img.png'}
]

export const Tornerai= () => {
    const [success, setSuccess] = useState(false)/**/

    const vote = (value) => {
        db.ref('/feedback').child(value).transaction(function (searches) {
            return (searches || 0) + 1
        })
            .then(()=> {
                setSuccess(true)
                setTimeout(() => setSuccess(false),3000)
            })
            .catch()
    }

    return(
        <div style={{textAlign:'center'}}>
            <p className='domanda' style={{margin:'0 0 1rem 0'}}>Ti è piaciuto il festival?</p>
            {success && <Check/>}
            <div style={{display:'flex', gap:'5rem', alignItems:'center', justifyContent:'center', paddingTop:'5rem'}}>
                {
                    why.map((item, index) =>
                        <div key={index} style={{cursor:'pointer', width:'10rem'}} onClick={() => vote(item.value)}>
                            <img src={item.icon} style={{width:'100%'}} alt={item.label} className='emoji'/>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
