import React from "react";

export const Check = () => (
    <div className='check-container'>
        <div className="success-checkmark">
            <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
            </div>
        </div>
        <div>
            <p style={{fontSize:'4rem', margin:0, fontWeight:"bold", color:'#4CAF50'}}>
                Grazie!
            </p>
        </div>
    </div>
)
