import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyBfhZWvkdL1_yPteLYMBbh_tW1OD2zWMpk",
    authDomain: "stereoteepee.firebaseapp.com",
    databaseURL:"https://stereoteepee-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "stereoteepee",
    storageBucket: "stereoteepee.appspot.com",
    messagingSenderId: "940140809771",
    appId: "1:940140809771:web:6faa567f7d8e745713a46e"
};


firebase.initializeApp(firebaseConfig)
const db = firebase.database()

export {db}
